import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { AccountPreferences } from '@/features/account-preferences';
import { LgmModule } from '@/features/lgm';
import { LgmAipSync } from '@/features/lgm/endorsement-tracker/AIPSync';
import { LrpPDFReportCtxProvider } from '@/features/lrp/endorsements/hooks/useLrpPDFReportCtx';
import LrpAlerts from '@/features/lrp/marketDynamics/alerts';
import { Production, ProductionGroup } from '@/features/lrp/production-groups';
import { LRPReports, Reports } from '@/features/reports';
import { Tags } from '@/features/tags/tags-management';

import userManager from '@/services/userManager';

const AppLayout = lazy(() => import('@/features/AppLayout'));
const ErrorBoundary = lazy(() => import('@/shared/components/common/ErrorBoundary'));

// Home Module
// /
const Home = React.lazy(() => import('@/modules/HomeModule'));
const home: RouteObject = {
  index: true,
  element: <Home />
};

// Login Module
const idpInitiatedLogin: RouteObject = {
  path: '/login/idpInitiated',
  loader: async () => {
    return await userManager.signinRedirect().catch(error => {
      throw new Response(`Something went wrong while redirecting to login page: ${error}`);
    });
  }
};

// /login
const login: RouteObject = {
  path: '/login',
  loader: async () => {
    const params = new URLSearchParams(window.location.search);
    const access_token = params.get('access_token') ?? '';

    return userManager
      .signinRedirect({
        extraQueryParams: {
          access_token: access_token
        }
      })
      .catch(error => {
        throw new Response(`Something went wrong while redirecting to login page: '${error}`);
      });
  }
};

// OperationRedirect Module
// /operationRedirect
const OperationRedirect = React.lazy(
  () => import('@/containers/DefaultOperationRedirectContainer/DefaultOperationRedirectContainer')
);
const operationRedirect: RouteObject = {
  path: '/operationRedirect',
  element: <OperationRedirect />
};

// Callback Module
// /callback
const Callback = React.lazy(() => import('@/callback'));
const callback: RouteObject = {
  path: '/callback/:type',
  element: <Callback />
};

// Operations Module
// /operations
const Operations = React.lazy(() => import('@/modules/OperationViewModule'));
const operations: RouteObject = {
  path: '/operations',
  element: <Operations />,
  errorElement: <ErrorBoundary />
};

// Admin Module
// /admin
const Admin = React.lazy(() => import('@/modules/AdminModule'));
const admin: RouteObject = {
  path: '/admin',
  element: <Admin />,
  errorElement: <ErrorBoundary />
};

// Account Preferences Module
// /account
const account: RouteObject = {
  path: '/account',
  element: <AccountPreferences />,
  errorElement: <ErrorBoundary />
};

// DRP Module
// /operation/calculator
const RevenuePrediction = React.lazy(() => import('@/modules/RevenuePredictionModule'));
const revenuePrediction: RouteObject = {
  path: 'calculator',
  element: <RevenuePrediction />,
  errorElement: <ErrorBoundary />
};

// /operation/endorsements
const EndorsementModule = React.lazy(() => import('@/modules/EndorsementModule'));
const endorsements: RouteObject = {
  path: 'endorsements',
  element: <EndorsementModule />,
  errorElement: <ErrorBoundary />
};

// /operation/overview/projection-charts
const ProjectedProductionContainer = React.lazy(
  () => import('@/containers/ProjectedProductionContainer/ProjectedProductionContainer')
);
const projectionCharts: RouteObject = {
  path: 'projection-charts',
  element: <ProjectedProductionContainer />
};

// /operation/overview/data
const OperationOverviewDataTable = React.lazy(
  () => import('@/containers/OperationOverviewDataTable/OperationOverviewDataTable')
);
const data: RouteObject = {
  path: 'data',
  element: <OperationOverviewDataTable />
};

// /operation/overview/preferences
const OperationPreferencesContainer = React.lazy(
  () => import('@/containers/OperationPreferencesContainer/OperationPreferencesContainer')
);
const preferences: RouteObject = {
  path: 'preferences',
  element: <OperationPreferencesContainer />
};

// /operation/overview/price-report
const OperationPriceReportContainer = React.lazy(
  () => import('@/containers/OperationPriceReportContainer/OperationPriceReportContainer')
);
const priceReport: RouteObject = {
  path: 'price-report',
  element: <OperationPriceReportContainer />
};

// /operation/overview/aip
const AIPContainer = React.lazy(() => import('@/containers/AIPContainer/AIPContainer'));
const aip: RouteObject = {
  path: 'aip',
  element: <AIPContainer />
};

// /operation/overview/contacts
const ContactsComponent = React.lazy(() => import('@/scenes/lrp/components/ContactsComponent'));
const contacts: RouteObject = {
  path: 'contacts',
  element: <ContactsComponent />
};

// /operation/overview/tags
const tags: RouteObject = {
  path: 'tags',
  element: <Tags />
};

// /operation/overview
const OperationOverview = React.lazy(() => import('@/modules/OperationOverviewModule'));
const operationOverview: RouteObject = {
  path: 'overview',
  element: <OperationOverview />,
  errorElement: <ErrorBoundary />,
  children: [projectionCharts, data, preferences, priceReport, aip, contacts, tags]
};

// /operation/market/price-trends
const PriceTrendsContainer = React.lazy(
  () => import('@/containers/PriceTrendsContainer/PriceTrendsContainer')
);
const priceTrends: RouteObject = {
  path: 'price-trends',
  element: <PriceTrendsContainer />,
  errorElement: <ErrorBoundary />
};
// Unsubscribe From Newsletter Module
// /unsubscribe
const UnsubscribeNewsletter = React.lazy(
  () => import('@/components/UnsubscribeNewsletter/UnsubscribeNewsletter')
);
const unsubscribeNewsletter: RouteObject = {
  path: '/unsubscribe/newsletter',
  element: <UnsubscribeNewsletter />,
  errorElement: <ErrorBoundary />
};
const unsubscribeAlerts: RouteObject = {
  path: 'unsubscribe/alert',
  element: <UnsubscribeNewsletter />,
  errorElement: <ErrorBoundary />
};
const unsubscribeLrpAlerts: RouteObject = {
  path: 'unsubscribe/alert/lrp',
  element: <UnsubscribeNewsletter />,
  errorElement: <ErrorBoundary />
};
const unsubscribeEndorsement: RouteObject = {
  path: 'unsubscribe/endorsement',
  element: <UnsubscribeNewsletter />,
  errorElement: <ErrorBoundary />
};

// /operation/market/alerts
const Alerts = React.lazy(() => import('@/containers/Alerts/Alerts'));
const alerts: RouteObject = {
  path: 'alerts',
  element: <Alerts />,
  errorElement: <ErrorBoundary />
};

// /operation/market/calendar
const DRPCalendar = React.lazy(() => import('@/containers/DRPCalendar/DRPCalendar'));
const calendar: RouteObject = {
  path: 'calendar',
  element: <DRPCalendar />,
  errorElement: <ErrorBoundary />
};

// /operation/market/price-reports
const PriceReportsContainer = React.lazy(
  () => import('@/containers/PriceReportsContainer/PriceReportsContainer')
);
const priceReports: RouteObject = {
  path: 'price-reports',
  element: <PriceReportsContainer />,
  errorElement: <ErrorBoundary />
};

// /operation/market/drp-sales-statistics
const DrpSalesStatistics = React.lazy(
  () => import('@/containers/DrpSalesStatistics/DrpSalesStatistics')
);
const drpSalesStatistics: RouteObject = {
  path: 'drp-sales-statistics',
  element: <DrpSalesStatistics />,
  errorElement: <ErrorBoundary />
};

// /operation/market
const MarketDynamicsModule = React.lazy(() => import('@/modules/MarketDynamicsModule'));
const market: RouteObject = {
  path: 'market',
  element: <MarketDynamicsModule />,
  errorElement: <ErrorBoundary />,
  children: [priceTrends, alerts, calendar, priceReports, drpSalesStatistics]
};

// /operation/scenario/endorsements
const EndorsementSimulation = React.lazy(
  () => import('@/components/ScenarioAnalysis/EndorsementSimulation')
);
const scenarioEndorsements: RouteObject = {
  path: 'endorsements',
  element: <EndorsementSimulation />,
  errorElement: <ErrorBoundary />
};

// /operation/scenario/projection-table
const ScenarioAnalysisProjectionTable = React.lazy(
  () => import('@/components/ScenarioAnalysis/ScenarioAnalysisProjectionTable')
);
const scenarioProjectionTable: RouteObject = {
  path: 'projection-table',
  element: <ScenarioAnalysisProjectionTable />,
  errorElement: <ErrorBoundary />
};

// /operation/scenario/data
const ScenarioAnalysisDataContainer = React.lazy(
  () => import('@/containers/ScenarioAnalysisDataContainer/ScenarioAnalysisDataContainer')
);
const scenarioData: RouteObject = {
  path: 'data',
  element: <ScenarioAnalysisDataContainer />,
  errorElement: <ErrorBoundary />
};

// /operation/scenario
const ScenarioAnalysisModule = React.lazy(() => import('@/modules/ScenarioAnalysisModule'));
const scenario: RouteObject = {
  path: 'scenario',
  element: <ScenarioAnalysisModule />,
  errorElement: <ErrorBoundary />,
  children: [scenarioEndorsements, scenarioProjectionTable, scenarioData]
};

// /operation
const OperationScreenModule = React.lazy(() => import('@/modules/OperationScreenModule'));
const operation: RouteObject = {
  path: '/operation/:name',
  element: <OperationScreenModule />,
  errorElement: <ErrorBoundary />,
  children: [revenuePrediction, endorsements, operationOverview, market, scenario]
};

// Education Module
// /education -- index route
const EducationOverview = React.lazy(() => import('@/features/education/pages/EducationOverview'));
const educationOverview: RouteObject = {
  index: true,
  element: <EducationOverview />,
  errorElement: <ErrorBoundary />
};

// /education/video-management
const VideoOverview = React.lazy(
  () => import('@/containers/EducationModuleContainers/VideoOverview')
);
const videoOverview: RouteObject = {
  path: 'video-management',
  element: <VideoOverview />,
  errorElement: <ErrorBoundary />
};

// /education/video
const VideoView = React.lazy(() => import('@/containers/EducationModuleContainers/VideoView'));
const videoView: RouteObject = {
  path: 'video/:id',
  element: <VideoView />,
  errorElement: <ErrorBoundary />
};

// /education/videos/:category
const VideoList = React.lazy(() => import('@/features/education/pages/EducationVideoListPage'));
const videoList: RouteObject = {
  path: 'videos/:category',
  element: <VideoList />,
  errorElement: <ErrorBoundary />
};

// /education
const Education = React.lazy(() => import('@/modules/EducationModule'));
const education: RouteObject = {
  path: '/education',
  element: <Education />,
  errorElement: <ErrorBoundary />,
  children: [educationOverview, videoOverview, videoView, videoList]
};

// management-reports/reports/drp
const OperationReport = React.lazy(
  () =>
    import(
      '@/containers/ManagementReportContainers/OperationReportContainer/OperationReportContainer'
    )
);
const drpReport: RouteObject = {
  path: 'drp',
  element: <OperationReport />,
  errorElement: <ErrorBoundary />
};

// management-reports/reports/lrp
const lrpReport: RouteObject = {
  path: 'lrp',
  element: <LRPReports />,
  errorElement: <ErrorBoundary />
};

// Management Reports Module
const operationReport: RouteObject = {
  path: 'reports',
  element: <Reports />,
  errorElement: <ErrorBoundary />,
  children: [drpReport, lrpReport]
};

// /management-reports/sms-usage-tracking/culmulative-report
const CulmulativeReport = React.lazy(
  () => import('@/containers/ManagementReportContainers/SmsUsageTracking/CulmulativeReport')
);
const culmulativeReport: RouteObject = {
  path: 'culmulative-report',
  element: <CulmulativeReport />
};

// /management-reports/sms-usage-tracking/detailed-report
const DetailedReport = React.lazy(
  () => import('@/containers/ManagementReportContainers/SmsUsageTracking/DetailedReport')
);
const detailedReport: RouteObject = {
  path: 'detailed-report',
  element: <DetailedReport />
};

// /management-reports/sms-usage-tracking
const SmsUsageTracking = React.lazy(
  () => import('@/containers/ManagementReportContainers/SmsUsageTracking/SmsUsageTrackingContainer')
);
const smsUsageTracking: RouteObject = {
  path: 'sms-usage-tracking',
  element: <SmsUsageTracking />,
  errorElement: <ErrorBoundary />,
  children: [culmulativeReport, detailedReport]
};

// unimplemented routes
const emptyChildren: RouteObject[] = [
  ['agent-report', 'Agent Report'],
  ['geo-report', 'Geo Report'],
  ['user-report', 'User Report']
].map(([path, element]) => ({
  path,
  element: <div>{element}</div>
}));

// /management-reports
const ManagementReports = React.lazy(() => import('@/modules/ManagementReportsModule'));
const managementReports: RouteObject = {
  path: '/management-reports',
  element: <ManagementReports />,
  errorElement: <ErrorBoundary />,
  children: [operationReport, smsUsageTracking, ...emptyChildren]
};

// LGM Module
// /lgm/calculator
const LgmPremiumQuoter = React.lazy(
  () => import('@/scenes/lgm/containers/RevenuePrediction/RevenuePrediction')
);
const lgmPremiumQuoter: RouteObject = {
  path: 'calculator',
  element: <LgmPremiumQuoter />,
  errorElement: <ErrorBoundary />
};

// /lgm/endorsements/active-endorsements
const LgmActiveEndorsements = React.lazy(
  () => import('@/features/lgm/endorsement-tracker/ActiveEndorsements')
);
const lgmActiveEndorsements: RouteObject = {
  path: 'active-endorsements',
  element: <LgmActiveEndorsements />,
  errorElement: <ErrorBoundary />
};

// /lgm/endorsements/aip-sync
const lgmAIPSync: RouteObject = {
  path: 'aip-sync',
  element: <LgmAipSync />,
  errorElement: <ErrorBoundary />
};

// /lgm/endorsements
const LgmEndorsesmentTracker = React.lazy(() => import('@/features/lgm/endorsement-tracker'));
const lgmEndorsesmentTracker: RouteObject = {
  path: 'endorsements',
  element: <LgmEndorsesmentTracker />,
  errorElement: <ErrorBoundary />,
  children: [lgmActiveEndorsements, lgmAIPSync]
};

const LgmPriceReportPreferences = React.lazy(
  () => import('@/features/lgm/operation-overview/components/Preferences')
);

// /overview/price-report-preferences
const lgmPriceReportPreferencesRoute: RouteObject = {
  path: 'price-report-preferences',
  element: <LgmPriceReportPreferences />,
  errorElement: <ErrorBoundary />
};

// /lgm/overview
const LgmOperationOverview = React.lazy(() => import('@/features/lgm/operation-overview'));
const lgmOperationOverviewRoute: RouteObject = {
  path: 'overview',
  element: <LgmOperationOverview />,
  errorElement: <ErrorBoundary />,
  children: [lgmPriceReportPreferencesRoute]
};

// /lgm
const lgm: RouteObject = {
  path: 'lgm/:name/:type',
  element: <LgmModule />,
  errorElement: <ErrorBoundary />,
  children: [lgmPremiumQuoter, lgmEndorsesmentTracker, lgmOperationOverviewRoute]
};

// LRP Module

// /lrp/market
const LrpMarketDynamicsModule = React.lazy(() => import('@/features/lrp/marketDynamics'));

// /lrp/market/price-trends
const LrpPriceTrends = React.lazy(
  () => import('@/scenes/lrp/containers/LrpMarketDynamics/PriceTrends/PriceTrends')
);
const lrpPriceTrends: RouteObject = {
  path: 'price-trends',
  element: <LrpPriceTrends />,
  errorElement: <ErrorBoundary />
};

// /lrp/market/price-evolution-analysis
const LrpPriceEvolutionAnalysis = React.lazy(
  () =>
    import(
      '@/scenes/lrp/containers/LrpMarketDynamics/PriceEvolutionAnalysis/PriceEvolutionAnalysis'
    )
);
const lrpPriceEvolutionAnalysis: RouteObject = {
  path: 'price-evolution-analysis',
  element: <LrpPriceEvolutionAnalysis />,
  errorElement: <ErrorBoundary />
};

// /lrp/market/price-evolution-analysis
const LrpCalendar = React.lazy(
  () => import('@/features/lrp/marketDynamics/lrpCalendar/LrpCalendar')
);
const lrpCalendar: RouteObject = {
  path: 'calendar',
  element: <LrpCalendar />,
  errorElement: <ErrorBoundary />
};

// /lrp/market/alerts
const lrpAlerts: RouteObject = {
  path: 'alerts',
  element: <LrpAlerts />,
  errorElement: <ErrorBoundary />
};

const lrpMarketDynamicsChildren: RouteObject[] = [lrpCalendar, lrpAlerts];

if (import.meta.env.VITE_APP_CLIENT_NAME === 'lia-fcs') {
  lrpMarketDynamicsChildren.push(lrpPriceTrends, lrpPriceEvolutionAnalysis);
}

const lrpMarketDynamics: RouteObject = {
  path: 'market',
  element: <LrpMarketDynamicsModule />,
  errorElement: <ErrorBoundary />,
  children: lrpMarketDynamicsChildren
};

const LrpPremiumQuoter = React.lazy(
  () => import('@/scenes/lrp/containers/LrpPremiumQuoter/LrpPremiumQuoter')
);
const lrpPremiumQuoter: RouteObject = {
  path: 'calculator',
  element: <LrpPremiumQuoter />
};

// /lrp/endorsements
const LrpEndorsements = React.lazy(
  () => import('@/scenes/lrp/containers/LrpEndorsements/LrpEndorsements')
);
const LrpEndorsementsWrappedInCtx = () => (
  <LrpPDFReportCtxProvider>
    <LrpEndorsements />
  </LrpPDFReportCtxProvider>
);
const lrpEndorsements: RouteObject = {
  path: 'endorsements',
  element: <LrpEndorsementsWrappedInCtx />
};

// /lrp/production/:group
const productionGroup: RouteObject = {
  path: ':group',
  element: <ProductionGroup />
};

// /lrp/production
const lrpProduction: RouteObject = {
  path: 'production',
  element: <Production />,
  children: [productionGroup]
};

// /lrp/overview
const LrpOperationOverview = React.lazy(
  () => import('@/scenes/lrp/containers/LrpPreferences/LrpOperationOverviewModule')
);
const lrpOperationOverview: RouteObject = {
  path: 'overview',
  element: <LrpOperationOverview />
};

const lrpChildren: RouteObject[] = [
  lrpPremiumQuoter,
  lrpEndorsements,
  lrpProduction,
  lrpOperationOverview,
  lrpMarketDynamics
];

// /lrp
const Lrp = React.lazy(() => import('@/scenes/lrp/LrpModule'));
const lrp: RouteObject = {
  path: 'lrp/:name',
  element: <Lrp />,
  errorElement: <ErrorBoundary />,
  children: [...lrpChildren]
};

// Coverage Request Review Module
// /coverage-request/review
const CoverageRequest = React.lazy(
  () => import('@/modules/CoverageRequestModule/CoverageRequestModule')
);
const coverageRequest: RouteObject = {
  path: '/coverage-request/review',
  element: <CoverageRequest />,
  errorElement: <ErrorBoundary />
};

// Review Coverage Request From Mail Module
// /review-request-coverage
const ReviewRequestCoverageFromMail = React.lazy(
  () => import('@/modules/ReviewCoverageRequestFromMail')
);
const reviewRequestCoverage: RouteObject = {
  path: '/review-request-coverage/:operationName/:refId',
  element: <ReviewRequestCoverageFromMail />,
  errorElement: <ErrorBoundary />
};

// User Agreement Module
// /user-agreement
const UserAgreement = React.lazy(() => import('@/components/UserAgreement/UserAgreement'));
const userAgreement: RouteObject = {
  path: 'user-agreement',
  element: <UserAgreement />
};

// Whats New Module
// /whats-new
const WhatsNew = React.lazy(() => import('@/scenes/whatsnew/WhatsNew'));
const whatsNew: RouteObject = {
  path: 'whats-new',
  element: <WhatsNew />
};

// 404 Module
const FourOFour = React.lazy(() => import('@/modules/FourOFour'));
const fourOFour: RouteObject = {
  path: '*',
  element: <FourOFour />
};

const routesChildren: RouteObject[] = [
  home,
  idpInitiatedLogin,
  login,
  operationRedirect,
  callback,
  operations,
  admin,
  account,
  lgm,
  lrp,
  coverageRequest,
  reviewRequestCoverage,
  userAgreement,
  unsubscribeNewsletter,
  unsubscribeAlerts,
  unsubscribeLrpAlerts,
  unsubscribeEndorsement,
  fourOFour
];

if (import.meta.env.VITE_APP_CLIENT_NAME === 'lia-fcs') {
  routesChildren.push(education, managementReports, operation, whatsNew);
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorBoundary />,
    children: [...routesChildren]
  }
];
export default routes;
