import { z } from 'zod';

import { LrpEndorsement, LrpEndorsementType } from '@/shared/api/lrpClient/lrpClient';

const LrpPDFFormOption = z.enum(['detail', 'summary']);
const LrpPDFFormOptions = z.object({
  option: LrpPDFFormOption
});
type LrpPDFFormOptionsType = z.infer<typeof LrpPDFFormOptions>;
enum WeightClasses {
  One = 1,
  Two = 2,
  Three = 3
}
const LrpWeightClasses = z.nativeEnum(WeightClasses);
type LrpWeightClass = z.infer<typeof LrpWeightClasses>;

const LrpPDFTableData = z.record(z.string(), z.array(LrpEndorsement).optional().nullable());
type LrpEndorsementGroup = {
  month: number;
  year: number;
  endorsements: LrpEndorsementType[];
  weightClass: LrpWeightClass;
};
type detailTotals = {
  numberOfHead: number;
  targetWeightAverage: number;
  producerPremium: number;
  netGuarantee: number;
  forecastedNetIndemnity: number;
  forecastedActualPrice: number;
};
type summaryTotals = {
  numberOfHead: number;
  grossEstimated: number;
  producerPremium: number;
  netEstimated: number;
};
type LrpDetailGroupWithTotals = LrpEndorsementGroup & {
  totals: detailTotals;
};
type LrpSummaryGroup = {
  month: number;
  year: number;
  endorsements: LrpEndorsementType[];
};
type LrpSummaryGroupWithTotals = LrpSummaryGroup & {
  totals: summaryTotals;
};
type LrpPDFTableDataType = z.infer<typeof LrpPDFTableData>;

export const weightGroups = {
  SteersWeight1: 1,
  HeifersWeight1: 1,
  BrahmanWeight1: 1,
  DairyWeight1: 1,
  NoTypeSpecified: 1,
  UnbornSwine: 1,
  UnbornSteersHeifers: 1,
  UnbornBrahman: 1,
  UnbornDairy: 1,
  SteersWeight2: 2,
  HeifersWeight2: 2,
  BrahmanWeight2: 2,
  DairyWeight2: 2,
  SteersHeifers: 3
} as const;

type WeightGroupKeys = keyof typeof weightGroups;
type WeightGroupVales = (typeof weightGroups)[WeightGroupKeys];

type LrpEndorsementsWithWeightClass = LrpEndorsementType & { weightClass: WeightGroupVales };

type MappedType<T> = Record<string, T>;
type LrpReportTableData = MappedType<LrpEndorsementsWithWeightClass[]>;
type LrpReportDetailData = MappedType<LrpDetailGroupWithTotals[]>;
type LrpReportSummaryData = MappedType<LrpSummaryGroupWithTotals[]>;

export { LrpPDFFormOptions, LrpPDFTableData, LrpWeightClasses };
export type {
  LrpPDFFormOptionsType,
  LrpPDFTableDataType,
  LrpReportTableData,
  LrpEndorsementGroup,
  LrpSummaryGroup,
  LrpReportSummaryData,
  LrpReportDetailData,
  LrpDetailGroupWithTotals,
  LrpSummaryGroupWithTotals,
  detailTotals,
  summaryTotals,
  LrpWeightClass,
  LrpEndorsementsWithWeightClass,
  WeightGroupKeys
};
